@import './base.css';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.alpha-chat-widget {
  @apply font-sans text-black bg-white;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
