:root {
  --primary: #ffffff;
  --primary-2: #f1f3f5;
  --secondary: #000000;
  --secondary-2: #111;
  --selection: var(--cyan);

  --text-primary: #000000;
  --text-secondary: white;

  --hover: rgba(0, 0, 0, 0.075);
  --hover-1: rgba(0, 0, 0, 0.15);
  --hover-2: rgba(0, 0, 0, 0.25);
  --cyan: #22b8cf;
  --green: #37b679;
  --red: #da3c3c;
  --purple: #f81ce5;
  --blue: #0070f3;

  --pink: #ff0080;
  --pink-light: #ff379c;

  --magenta: #eb367f;

  --violet: #7928ca;
  --violet-dark: #4c2889;

  --accent-0: #fff;
  --accent-1: #fafafa;
  --accent-2: #eaeaea;
  --accent-3: #999999;
  --accent-4: #888888;
  --accent-5: #666666;
  --accent-6: #444444;
  --accent-7: #333333;
  --accent-8: #111111;
  --accent-9: #000;

  --font-sans: -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue',
    'Helvetica', sans-serif;
}

[data-theme='dark'] {
  --primary: #000000;
  --primary-2: #111;
  --secondary: #ffffff;
  --secondary-2: #f1f3f5;
  --hover: rgba(255, 255, 255, 0.075);
  --hover-1: rgba(255, 255, 255, 0.15);
  --hover-2: rgba(255, 255, 255, 0.25);
  --selection: var(--purple);

  --text-primary: white;
  --text-secondary: black;

  --accent-9: #fff;
  --accent-8: #fafafa;
  --accent-7: #eaeaea;
  --accent-6: #999999;
  --accent-5: #888888;
  --accent-4: #666666;
  --accent-3: #444444;
  --accent-2: #333333;
  --accent-1: #111111;
  --accent-0: #000;
}
